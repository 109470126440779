<template>
  <div class="report-uploads-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="getUploads"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <button class="ml-1 mr-2 btn btn-primary btn-sm" @click="onOpenUploadModal">
                    <i class="uil uil-upload"></i> Upload File
                  </button>
                  <button class="btn btn-primary btn-sm" @click="getUploads">
                    <i class="uil uil-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loading">
              <div class="table-responsive mb-0">
                <b-table :items="uploads" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page"
                  :no-local-sorting="true" @sort-changed="sortChanged">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(size)="data">
                    {{ data.item.size | prettyBytes }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span :class="{ 'text-success': data.item.status === 'completed' }" class="font-weight-bold text-primary">
                      {{ data.item.status | capitalize }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | moment('MM-DD-YYYY hh:mm A') }}
                  </template>
                  <template v-slot:cell(actions)="data">
                    <a class="btn btn-sm btn-primary ml-1" title="Download File" :href="data.item.download_url" download>
                      <i class="uil uil-download-alt"></i>
                    </a>
                    <!-- <b-button variant="danger" size="sm" title="Delete" class="ml-1"
                      :disabled="loadingRemove === data.item.id" @click="remove(data.item)">
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button> -->
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalUploads }} records
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="totalUploads"
                        :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="upload-modal" v-model="showUploadModal" title="Upload File">
      <div class="row">
        <!-- <div class="col-md-12 mb-3">
          <div class="form-group text-center">
            <b-form-radio v-model="uploadType" name="type" value="MDR" class="d-inline">MDR</b-form-radio>
            <b-form-radio v-model="uploadType" name="type" value="BDR" class="d-inline ml-4">BDR</b-form-radio>
          </div>
        </div> -->
        <div class="col-md-12">
          <UploadFile v-model="fileName" accept=".xlsx, .xls, .csv" label="Select File to Upload"
            @upload="onUploadFile" />
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showUploadModal = false">Cancel</b-button>
        <b-button variant="primary" :disabled="!file || processing" @click="handleUploadFile">
          <b-spinner v-if="processing" small></b-spinner>
          <span v-else>Upload</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Business">
      <p v-if="selectedUpload">Are you sure you want to delete this record <b>"#{{
        selectedUpload.id
      }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'type', sortable: true },
          { key: 'file_name', sortable: true },
          { key: 'size', sortable: true },
          { key: 'status', label: 'Status', sortable: true },
          { key: 'created_at', label: 'Uploaded At', sortable: true },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      loading: false,
      loadingRemove: false,
      processing: false,
      showUploadModal: false,
      fileName: '',
      file: null,
      showDeleteModal: false,
      selectedUpload: null,
      uploadType: 'MDR',
    }
  },

  computed: {
    totalUploads() {
      return this.$store.getters['upload/total']
    },

    uploads() {
      return this.$store.getters['upload/all'] || []
    },
  },

  created() {

  },

  mounted() {
    this.getUploads()
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getUploads()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getUploads()
    },

    onOpenUploadModal() {
      this.showUploadModal = true
      this.fileName = null
      this.file = null
    },

    getUploads() {
      this.loading = true

      this.$store.dispatch('upload/getAll', this.datatable.queries)
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    onUploadFile(val) {
      this.file = val
    },

    handleUploadFile() {
      let uploadForm = new FormData()
      uploadForm.append('file', this.file)
      uploadForm.append('type', this.uploadType)
      this.processing = true

      this.$store
        .dispatch('upload/create', uploadForm)
        .then((res) => {
          this.processing = false
          this.showUploadModal = false
          this.getUploads();
        })
        .catch(() => {
          this.processing = false
          this.showUploadModal = false
        })
    },

    remove(item) {
      this.showDeleteModal = true
      this.selectedUpload = item
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedUpload.id
      this.$store
        .dispatch('upload/destroy', this.selectedUpload.id)
        .then(() => {
          this.loadingRemove = null
          this.getUploads()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>