<template>
  <div class="report-records-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="getRecords"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center justify-content-end">
                  Status:
                  <select v-model="datatable.queries.status" class="form-control ml-2 form-control-sm" style="width: 150px">
                    <option value="">All</option>
                    <option value="verified">Verified</option>
                    <option value="pending">Pending</option>
                    <option value="declined_brand">Decline - Brand</option>
                    <option value="declined_campaigin">Decline - Campaign</option>
                    <option value="deactivated">Deactivated</option>
                    <option value="not_approvoed">Not Approved</option>
                  </select>
                  <b-form-input v-model="datatable.queries.search" type="search" placeholder="Search..."
                    class="form-control form-control-sm ml-2" style="width: 200px"></b-form-input>
                  <button class="ml-1 btn btn-primary btn-sm" @click="onQueryChange('search')">
                    <i class="uil uil-search-alt"></i>
                  </button>
                  <button class="ml-1 btn btn-primary btn-sm" @click="refresh">
                    <i class="uil uil-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loading">
              <div class="table-responsive mb-0">
                <b-table :items="records" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page"
                  :no-local-sorting="true" @sort-changed="sortChanged">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(sent_timestamp)="data">
                    {{ data.item.sent_timestamp | moment('MM-DD-YYYY hh:mm:ss A') }}
                  </template>
                  <template v-slot:cell(phone)="data">
                    {{ data.item.phone | nationalNumber }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span v-if="data.item.status">
                      {{ data.item.status | capitalize }}
                    </span>
                  </template>
                  <template v-slot:cell(no_messages)="data">
                    {{ data.item.no_messages.toLocaleString() }}
                  </template>
                  <template v-slot:cell(no_messages_total)="data">
                    {{ data.item.no_messages_total.toLocaleString() }}
                  </template>
                  <template v-slot:cell(cost)="data">
                    $ {{ data.item.cost.toLocaleString() }}
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-button variant="primary" size="sm" title="Sign In" :disabled="processing"
                      @click="impersonate(data.item.agency_owner_id)">
                      <b-spinner v-if="processing" small />
                      <i v-else class="uil uil-sign-out-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalRecords }} records
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="totalRecords"
                        :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      datatable: {
        columns: [
          // { key: 'id', label: '#', sortable: false },
          { key: 'agency_name', label: 'Agency Name', sortable: true },
          { key: 'business_name', label: 'Business Name', sortable: true },
          { key: 'phone', label: 'Phone Number', sortable: false },
          { key: 'status', sortable: true },
          { key: 'no_messages', label: 'Messages', sortable: false },
          { key: 'no_messages_total', label: 'Messages (total)', sortable: false },
          { key: 'cost', label: 'Cost', sortable: false },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: '',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      records: [],
      totalRecords: null,
      loading: false,
      processing: false,
    }
  },

  computed: {
  },

  mounted() {
    this.getRecords()
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getRecords()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getRecords()
    },

    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search') {
          this.datatable.queries.page = 1;
        }
        this.getRecords()
      });
    },

    getRecords() {
      this.loading = true
      this.records = [];

      this.$store.dispatch('report/adminBusinessReport', this.datatable.queries)
        .then((res) => {
          this.records = res.data
          this.totalRecords = res.meta.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    refresh() {
      this.datatable.queries.search = null
      this.datatable.queries.status = ''
      this.getRecords();
    },

    impersonate(userId) {
      this.processing = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.processing = false
            })
            .catch(() => {
              this.processing = false
            })
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>