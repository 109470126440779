<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-tabs v-model="activeTabIndex">
          <!-- <b-tab title="MDR" active>
            <Mdr v-if="activeTabIndex === 0"/>
          </b-tab> -->
          <!-- <b-tab title="BDR">
            <Bdr v-if="activeTabIndex === 1"/>
          </b-tab> -->
          <b-tab title="Businesses">
            <Businesses v-if="activeTabIndex === 0"/>
          </b-tab>
          <b-tab title="Uploads">
            <Uploads v-if="activeTabIndex === 1"/>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import Mdr from './Mdr'
// import Bdr from './Bdr'
import Uploads from './Uploads'
import Businesses from './Businesses'

export default {
  components: { 
    // Mdr,
    // Bdr,
    Uploads, Businesses,
  },

  data() {
    return {
      activeTabIndex: 0,
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>